<template>
	<div>
		<b-modal
			id="modal-audit-log-detail"
			cancel-variant="outline-secondary"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			modal-class="modal-primary"
			centered
			:title="$t('User audit log detail')"
			size="lg"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<b-table-simple
				striped
				responsive
				bordered
				class="table-white-space"
			>
				<b-tbody>
					<b-tr>
						<b-th>{{ $t('Event type') }}</b-th>
						<b-td>{{ detailDatas.eventTypeName }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Table name') }}</b-th>
						<b-td>{{ detailDatas.tableName ? detailDatas.tableName : 'null' }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Actions') }}</b-th>
						<b-td>{{ detailDatas.activity }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Updated by') }}</b-th>
						<b-td>{{detailDatas.staff }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>IP</b-th>
						<b-td>{{ auditLogData ? auditLogData.ip : 'null' }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Remark') }}</b-th>
						<b-td>{{ auditLogData ? auditLogData.note : 'null' }}</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Details') }}</b-th>
						<b-td>
							<b-table-simple
								responsive
								bordered
							>
								<b-tbody v-if="auditLogData">
									<b-tr>
										<b-td>{{ $t('Field') }}</b-td>
										<b-td>{{ $t('Old value') }}</b-td>
										<b-td>{{ $t('New value') }}</b-td>
									</b-tr>
									<b-tr
										v-for="(item, index) in newData(auditLogData.diff)"
										:key="index"
										class="text-capitalize"
									>
										<b-td>{{ item.field }}</b-td>
										<b-td>{{ item.old }}</b-td>
										<b-td>{{ item.new }}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-td>
					</b-tr>
					<b-tr>
						<b-th>{{ $t('Created at') }}</b-th>
						<b-td>{{ auditLogData ? formatDateTime(auditLogData.created_at).replace('.000000Z', '') : 'null' }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BOverlay, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BTableSimple, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatDateTime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BOverlay, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BTableSimple, BTr, BTd, BTh, BTbody,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		auditLogId: {
			type: Number,
			default: null,
		},
		detailDatas: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			auditLogData: null,
			show: false,
		}
	},
	watch: {
		async auditLogId(newVal) {
			if (newVal != -1) {
				this.show = true
				await this.fetchAuditLogDetail(newVal)
				this.show = false
			}
		},
	},
	methods: {
		async fetchAuditLogDetail(auditLogData) {
			await this.$store.dispatch('app-user/fetchUserAuditLogDetail', {id: auditLogData})
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.auditLogData = response.data.data
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		},
		resetModal() {
			this.$emit('update:auditLogId', -1)
			this.auditLogData = null
		},
	},
	setup() {
		// const new_pkg = { scooter_id: 'TM0060', lat: '25.747746', lng: '21.231496', alt: '99.200' }
		// const old_pkg = { scooter_id: 'TM0060', lat: '25.747746', lng: '21.231496', alt: '98.200' }

		const resolveUserStatus = stat => {
			if (stat === 3) return 'Locked'
			if (stat === 1) return 'Active'
			if (stat === 2) return 'Verify'
			return 'None'
		}

		const newData = diff => {
			const test = []
			if (typeof diff === 'object') {
				Object.entries(diff).forEach(([key, value], index) => {
					test[index] = {
						field: key,
						old: value.old_value,
						new: value.new_value,
					}
				})
			}
			return test
		}

		const getNew = (newObject, oldObject) => {
			if (typeof newObject === 'string' && typeof oldObject === 'string') {
				const newObj = JSON.parse(newObject)
				const oldObj = JSON.parse(oldObject)
				if (Object.keys(oldObj).length == 0 && Object.keys(newObj).length > 0) return newObj

				const test = []
				const restore = []

				Object.entries(oldObj).forEach(([key, value], index) => {
					restore[index] = {
						field: key,
						old: oldObj[key],
						new: newObj[key],
					}
					if (newObj[key] && oldObj[key] != newObj[key]) {
						test[index] = {
							field: key,
							old: oldObj[key],
							new: newObj[key],
						}
					}
				})

				if (Object.keys(test).length > 0) return test
				return restore
			}
		}

		// console.log(getNew(new_pkg, old_pkg))

		return {
			formatDateTime,
			getNew,
			newData,
			resolveUserStatus,
		}
	},
}
</script>